(function() {
    angular.module('EntrakV5').controller('timetableController', timetableController);

    function timetableController($scope, $timeout, $rootScope, Service, Api, KEY, APIKEY, $state, $stateParams) {
        console.log('timetableController');

        var caller = Api.createApiCaller();
        $scope.btnStatus = {};
        $rootScope.hideTabs = false;

        $scope.day = 0;
        $scope.tenantNames = [];
        $scope.dataMap = {};

        $scope.hours = ["00", "02", "04", "06", "08", "10", "12", "14", "16", "18", "20", "22"];
        var minuteOffset = new Date().getTimezoneOffset();

        var nameSorter = Service.getSorter();

        $scope.dayDropdownOpt = {
            autoWidth: true,
            dataSource: [{
                name: Service.translate("label.today"),
                value: 0
            }, {
                name: Service.translate("label.yesterday"),
                value: 1
            }],
            dataTextField: "name",
            dataValueField: "value",
            change: function(){
              $scope.loadTimeslots();
            }
        }

        $rootScope.loadingPage = 1;
        caller.call(Api.getDisplayTenants(true, false)).then(function(tenants){
            $scope.tenantNames = tenants.sort(nameSorter);
            $rootScope.loadingPage--;
        }, function(err){
            if (err === KEY.ignore)
                return;
            $rootScope.loadingPage--;
            alert(Service.translate("error.generalGetDataFail"));
        });

        function getScheduleTimestamp(timezone, timeslotTime, baseTimestamp) {
            var schDate = Service.getLocalDatetime(timezone, timeslotTime.hour, timeslotTime.minute, baseTimestamp);
            return Service.getUnixTimestamp(schDate);
        }
        function isTimeslotActionTriggered(lastTrigger, timestamp, action) {
            if (action === "AUTO") {
                if (lastTrigger >= timestamp) {
                    return true;  // triggered
                } else {
                    return false;  // not triggered
                }
            } else {
                return null;  //no need to trigger
            }
        }

        $scope.loadTimeslots = function() {
            $rootScope.loadingPage++;
            var dayOffset = $scope.day;
            var now = new Date();
            now.setSeconds(0);
            now.setMilliseconds(0);
            var dayOfWeekName = APIKEY.dayKey[(APIKEY.dayKey.length + now.getDay() - dayOffset) % APIKEY.dayKey.length];
            var today = Service.dateFmt(now, "yyyy-MM-dd");
            caller.call([Api.getAllTimeslots(dayOfWeekName), Api.getHolidays(today)]).then(function(res){
                var holidayMap = Service.arrayToMap(res[1], "timetableId");
                var nowTimestamp = Service.getUnixTimestamp(now);
                var dayOffsetTimestamp = nowTimestamp - 60*60*24*dayOffset;
                var map = {};  //{ TENANT_ID: { TIMETABLE_ID: { id:x name:x timeslots: [...] } timetables: [...] } }
                res[0].forEach(function(ts){
                    if (!map[ts.tenantId])
                        map[ts.tenantId] = {};
                    var subMap = map[ts.tenantId];
                    if (!subMap[ts.timetableId])
                        subMap[ts.timetableId] = { id: ts.timetableId, name: ts.timetableName, timeslots: [] };
                    subMap[ts.timetableId].timeslots.push(ts);
                });
                Object.values(map).forEach(function(tenant){
                    tenant.timetables = Object.values(tenant);
                    tenant.timetables.sort(nameSorter);
                    tenant.timetables.forEach(function(tt){
                        Service.sortSameDayTimeslot(tt.timeslots);
                        if (holidayMap[tt.id])
                            tt.holiday = holidayMap[tt.id];
                        tt.timezone = tt.timeslots[0].timezone;
                        tt.timezoneDiff = Service.getTimezoneDiffInMilliseconds(tt.timezone) / 1000;
                        if (tt.timezoneDiff) {
                            tt.timezoneLbl = (-minuteOffset + tt.timezoneDiff / 60) / 60;
                            tt.timezoneLbl = (tt.timezoneLbl > 0 ? "(+" : "(") + tt.timezoneLbl + ")";
                        }
                        tt.timeslots.forEach(function(ts){
                            ts.displayLastTrigger = ts.lastTrigger + tt.timezoneDiff;
                            ts.left = (ts.start.hour * 60 + ts.start.minute) / 1440 * 100 + "%";
                            ts.right = 100 - (ts.end.hour * 60 + ts.end.minute) / 1440 * 100 + "%";
                            ts.label = Service.timeslotTimeToStr(ts.start) + "-" + Service.timeslotTimeToStr(ts.end);
                            var endTimestamp = getScheduleTimestamp(ts.timezone, ts.end, dayOffsetTimestamp);
                            var startTimestamp = getScheduleTimestamp(ts.timezone, ts.start, dayOffsetTimestamp);
                            if (nowTimestamp > endTimestamp) {
                                var endTriggered = isTimeslotActionTriggered(ts.lastTrigger, endTimestamp, ts.end.action);
                                if (endTriggered !== null) 
                                    ts.colorClass = endTriggered ? "green" : "red";
                            } else if (nowTimestamp > startTimestamp) {
                                var startTriggered = isTimeslotActionTriggered(ts.lastTrigger, startTimestamp, ts.start.action);
                                if (startTriggered !== null)
                                    ts.colorClass = startTriggered ? "green" : "red";
                            } else {
                                // no color for future timeslot
                            }
                        });
                    });
                });
                $scope.dataMap = map;
                $rootScope.loadingPage--;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $rootScope.loadingPage--;
                alert(Service.translate("error.generalGetDataFail"));
            });
          
        }
        $scope.loadTimeslots();

        $scope.triggerEnforceTimetable = function(id) {
          $scope.btnStatus[id] = true;
          caller.call(Api.enforceTimetable(id)).then(function(){
              $scope.btnStatus[id] = false;
          }, function(err){
              if (err === KEY.ignore)
                  return;
              $scope.btnStatus[id] = false;
              alert(Service.translate("error.generalUpdate"));
          });
        }

        $scope.tsPopupData = null;
        $scope.tsPopupOpt = {
            // anchor: $("#timetable .etTableBody .timeslotBars div"),
            origin: "top right",
            position: "top left",
        }
        $scope.showPopup = function(tsData, elmId){
            if (tsData === $scope.tsPopupData) {
                $scope.tsPopupData = null;
                $scope.tsPopup.close();
                return;
            }
            $scope.tsPopup.close();
            $scope.tsPopup.setOptions({anchor: $("#"+elmId)});
            setTimeout(function(){    //wait after popup closed
                $scope.tsPopup.open();
            }, 200);

            $scope.tsPopupData = tsData;
        }

        $scope.$on('$destroy', function() {
            console.log("timetableController destroy");
            caller.cancel();
        });
    }
})();
